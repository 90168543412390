import { SurveyActions } from "app/surveys/modules/actions/SurveyActions"
import { ReportMyGoals, SurveyResult, User } from "app/surveys/modules/state/model/Model"
import { LogicComponent } from "app/surveys_app/components/base/LogicComponent"
import { LogicComponentProps, LogicComponentState } from "core/components/base/LogicComponent"
import { LocalizationState } from "core/modules/state/model/Model"
import { printPdf } from "lib/services/pdf"
import { isMobile } from "lib/ui/compatibility/Browsers"
import { downloadBlob } from "lib/utils/FileUtils"
import posthog from "posthog-js"
import shortid from "shortid"
import ReportPageView from "./ReportPageView"

interface ReportPageProps extends LogicComponentProps {
  reportId?: string
}

interface ReportPageState extends LogicComponentState {
  language?: string
  loading: boolean
  resultId?: string
  selectedReportIndex: number
  isDownloadingPdf?: boolean
}

export default class ReportPage extends LogicComponent<ReportPageProps, ReportPageState> {
  declare surveyActions: SurveyActions

  constructor(props: ReportPageProps) {
    super(props)

    const params = new URLSearchParams(location.search)

    // Set loading initially true to make blockdiv animation behave nicer
    this.state = {
      loading: true,
      resultId: undefined,
      selectedReportIndex: parseInt(params.get("index") ?? "0") || 0,
      isDownloadingPdf: false
    }
  }

  get dependencies(): string[] {
    return ["SurveyActions"]
  }

  private get pdfName() {
    return `${slugifyFileName(this.txt("title"))}.pdf`
  }

  private get pdfUrl() {
    const url = new URL(window.location.href)
    url.searchParams.set("language", this.props.language ?? "en")
    url.searchParams.set("printmode", "1")
    url.searchParams.set("index", this.state.selectedReportIndex.toString())

    return url.toString()
  }

  render() {
    super.render()

    const { selectedReportIndex } = this.state

    return (
      <ReportPageView
        reportVariant={this.getReportVariant()}
        language={this.state.language}
        isLoading={this.state.loading}
        surveyResult={this.surveyResult}
        user={this.user}
        showCoachInfo={this.isCoachInfoShown()}
        onSetLanguage={this.setLanguage}
        selectedReportIndex={selectedReportIndex}
        isDownloadingPdf={this.state.isDownloadingPdf}
        onDownloadPdf={this.onDownloadPdf}
        onSelectReport={this.onSelectReport}
        onUpdateMyGoals={this.onUpdateMyGoals}
      />
    )
  }

  componentDidMount() {
    const { reportId } = this.props

    const params = new URLSearchParams(location.search)
    const language = params.get("language") || "en"
    this.setLanguage(language)

    reportId && this.loadReport(reportId)
  }

  private onUpdateMyGoals = async (reportMyGoals: ReportMyGoals) => {
    const { reportId } = this.props

    reportMyGoals = {
      highLevelGoal: (reportMyGoals.highLevelGoal ?? "").trim(),
      myGoal: (reportMyGoals.myGoal ?? "").trim(),
      myActions_1: (reportMyGoals.myActions_1 ?? "").trim(),
      myActions_2: (reportMyGoals.myActions_2 ?? "").trim(),
      myActions_3: (reportMyGoals.myActions_3 ?? "").trim(),
      obstacles: (reportMyGoals.obstacles ?? "").trim()
    }

    await this.surveyActions.updateReportExtra(reportId!, this.surveyResult?.uuid!, { reportMyGoals: reportMyGoals })
  }

  async loadReport(id: string) {
    try {
      this.setState({ loading: true })

      posthog.capture("report_loading")

      const surveyResult = await this.surveyActions.getDocument<SurveyResult>(id, "SurveyResult")

      posthog.capture("report_loaded")
      if (surveyResult?.instances[0]?.organisation_name?.toLowerCase().includes("[demo]")) {
        posthog.identify(`[test]_${shortid.generate()}`, { testUser: true })
      }

      this.setState({ resultId: surveyResult?.id })
    } catch {
      // @TODO: Add error message
    } finally {
      this.setState({ loading: false })
    }
  }

  private setLanguage = (language: string) => {
    posthog.capture("set_language", { language })
    this.updateDefault<LocalizationState>("LocalizationState", state => (state.language = language))
    this.setState({ language })
  }

  private onSelectReport = (index: number) => {
    posthog.capture("report_select", { index })
    this.setState({ selectedReportIndex: index })
  }

  private onDownloadPdf = async () => {
    this.setState({ isDownloadingPdf: true })

    try {
      posthog.capture("download_pdf", { url: this.pdfUrl })

      const [pdf, error] = await printPdf(this.pdfUrl)

      posthog.capture("download_pdf_complete", { url: this.pdfUrl })

      if (error) {
        posthog.capture("download_pdf_failed", { url: this.pdfUrl, error })
        alert(`Download failed: ${error}`)
      } else if (pdf) {
        downloadBlob(pdf, this.filename || "download.pdf")
      }
    } finally {
      this.setState({ isDownloadingPdf: false })
    }
  }

  get surveyResult() {
    const surveyResult = Object.assign({}, this.doc<SurveyResult>(this.state.resultId, "SurveyResult"))

    if (!surveyResult) return undefined

    surveyResult.instances = surveyResult.instances?.filter(instance => instance.completed) ?? []

    return surveyResult
  }

  get user() {
    const userId = this.surveyResult?.user?.id

    return userId ? this.doc<User>(userId, "User") : undefined
  }

  private getReportVariant() {
    if (this.isPrintMode()) return "pdf"

    if (isMobile()) return "mobile"

    return "desktop"
  }

  private isCoachInfoShown() {
    const params = new URLSearchParams(location.search)
    return params.get("coach") === "1"
  }

  private isPrintMode() {
    try {
      const params = new URLSearchParams(location.search)

      const mediaQueryList = window.matchMedia("print")
      return mediaQueryList.matches || params.get("printmode") === "1"
    } catch (e) {
      return false
    }
  }
}
