import Container from "@material-ui/core/Container"
import { style, stylesheet } from "typestyle"

import { ReportMyGoals, Survey, SurveyAnswerInstance, User } from "app/surveys/modules/state/model/Model"
import { ViewComponentProps } from "core/components/base/ViewComponent"

import { ViewComponent } from "../../../base/ViewComponent"
import ReportSectionCongratulations from "./sections/1_congratulations/ReportSectionCongratulations"
import ReportSectionCoblCircle from "./sections/2_cobl_circle/ReportSectionCoblCircle"
import ReportSectionCoblCategories from "./sections/3_cobl_categories/ReportSectionCoblCategories"
import ReportSectionBigPicture from "./sections/4_big_picture/ReportSectionBigPicture"
import ReportSectionSurvivingPerformingThriving from "./sections/5_surviving_performing_thriving/ReportSectionSurvivingPerformingThriving"
import ReportSectionElementDetails from "./sections/6_element_details/ReportSectionElementDetails"
import ReportSectionMyPlan from "./sections/7_my_plan/ReportSectionMyPlan"
import ReportSectionContinueJourney from "./sections/8_continue_journey/ReportSectionContinueJourney"
import ReportSectionCustomArea from "./sections/9_custom_area/ReportSectionCustomArea"
import ReportSectionFooter from "./sections/10_footer/ReportSectionFooter"
import ReportSectionCoachInfo from "./sections/11_coach_info/ReportSectionCoachInfo"

export type ReportVariant = "desktop" | "mobile" | "pdf"

interface PageProps extends ViewComponentProps {
  answer: SurveyAnswerInstance
  comparison: SurveyAnswerInstance | undefined
  isInitialReport: boolean
  language: string
  myGoals?: ReportMyGoals
  pdfName?: string
  pdfUrl: string
  reportVariant: ReportVariant
  showCoachInfo: boolean
  survey: Survey
  user: User | undefined
  isDownloadingPdf: boolean
  onDownloadPdf: () => void
  onUpdateMyGoals: (reportMyGoals: ReportMyGoals) => void
}

export default class WellbeingReport extends ViewComponent<PageProps> {
  get componentName() {
    return ["report", "pages", "report", "WellbeingReport"]
  }

  private isFirstRender = true

  constructor(props: PageProps) {
    super(props)

    setupStyles(props.reportVariant)
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      this.isFirstRender ||
      nextProps.language !== this.props.language ||
      nextProps.isDownloadingPdf !== this.props.isDownloadingPdf
    ) {
      this.isFirstRender = false
      return true
    }

    return false
  }

  render() {
    super.render()

    const {
      answer,
      comparison,
      language,
      myGoals,
      pdfName,
      pdfUrl,
      reportVariant,
      showCoachInfo,
      survey,
      isInitialReport,
      isDownloadingPdf,
      onDownloadPdf,
      onUpdateMyGoals
    } = this.props

    if (!answer) return null

    return (
      <Container maxWidth="lg" className={reportVariant === "mobile" ? styles.containerMobile : undefined}>
        <div>
          <ReportSectionCongratulations reportVariant={reportVariant} isInitialReport={isInitialReport} />
          <ReportSectionCoblCircle
            answer={answer}
            comparison={comparison}
            language={language}
            reportVariant={reportVariant}
          />
          <ReportSectionCoblCategories answer={answer} comparison={comparison} reportVariant={reportVariant} />
          <ReportSectionBigPicture answer={answer} comparison={comparison} reportVariant={reportVariant} />
          <ReportSectionSurvivingPerformingThriving
            answer={answer}
            comparison={comparison}
            reportVariant={reportVariant}
          />
          <ReportSectionElementDetails answer={answer} comparison={comparison} reportVariant={reportVariant} />
          {isInitialReport && (
            <ReportSectionMyPlan
              answer={answer}
              comparison={comparison}
              reportVariant={reportVariant}
              myGoals={myGoals}
              onUpdateMyGoals={onUpdateMyGoals}
            />
          )}
          <ReportSectionContinueJourney reportVariant={reportVariant} isInitialReport={isInitialReport} />
          <ReportSectionCustomArea survey={survey} reportVariant={reportVariant} />
          <ReportSectionFooter
            reportVariant={reportVariant}
            isDownloadingPdf={isDownloadingPdf}
            onDownloadPdf={onDownloadPdf}
          />
          {showCoachInfo && (
            <ReportSectionCoachInfo answer={answer} reportVariant={reportVariant} isInitialReport={isInitialReport} />
          )}
        </div>
      </Container>
    )
  }
}

const styles = stylesheet({
  containerMobile: { padding: "0 !important" }
})

export let reportLargeHeadingClass: string
export let reportMediumHeadingClass: string
export let reportSmallHeadingClass: string
export let reportSubHeadingClass: string
export let reportBodyClass: string
export let reportSmallBodyClass: string
export let reportLargeBodyClass: string

const setupStyles = (repoortVariant: ReportVariant) => {
  reportLargeHeadingClass = style({
    fontSize: repoortVariant === "mobile" ? "52px" : "100px",
    fontWeight: 300,
    letterSpacing: "-2.4px",
    marginBottom: "1rem",
    lineHeight: repoortVariant === "mobile" ? "110%" : "normal"
  })

  reportMediumHeadingClass = style({
    fontSize: repoortVariant === "mobile" ? "38px" : "48px",
    fontWeight: 500,
    lineHeight: "100%"
  })

  reportSmallHeadingClass = style({
    fontSize: repoortVariant === "mobile" ? "30px" : "48px",
    fontWeight: 300,
    lineHeight: "120%"
  })

  reportSubHeadingClass = style({
    fontSize: "20px",
    textTransform: "uppercase",
    color: ReportSectionCoblCircle.theme.colors.hintsa.alabaster.toString()
  })

  reportLargeBodyClass = style({
    fontSize: repoortVariant === "mobile" ? "28px" : "36px",
    fontWeight: 400,
    lineHeight: "120%"
  })

  reportBodyClass = style({
    fontSize: repoortVariant === "mobile" ? "18px" : "22px",
    lineHeight: "120%"
  })

  reportSmallBodyClass = style({
    fontSize: "16px"
  })
}
