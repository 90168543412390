import Container from "@material-ui/core/Container"
import { stylesheet } from "typestyle"

import { ReportMyGoals, Survey, SurveyResult, User } from "app/surveys/modules/state/model/Model"
import ReportTopBanner from "app/surveys_app/components/survey/banners/ReportTopBanner"
import { AppConfig } from "app/surveys_app/modules/config/appConfig"
import { ViewComponentProps } from "core/components/base/ViewComponent"
import FadedBlockDiv from "lib/ui/components/layout/FadedBlockDiv"
import TransformElement from "lib/ui/components/layout/TransformElement"
import { slugifyFileName } from "lib/utils/TextUtils"
import { ViewComponent } from "../../../base/ViewComponent"
import WellbeingReport, { ReportVariant } from "./WellbeingReport"
import ReportSelector from "./report_selector/ReportSelector"

interface Props extends ViewComponentProps {
  isLoading: boolean
  language: string | undefined
  reportVariant: ReportVariant
  selectedReportIndex: number
  showCoachInfo: boolean
  surveyResult: SurveyResult | undefined
  user: User | undefined
  isDownloadingPdf: boolean
  onDownloadPdf: () => void
  onSelectReport: (index: number) => void
  onSetLanguage: (language: string) => void
  onUpdateMyGoals: (reportMyGoals: ReportMyGoals) => void
}

export default class ReportPageView extends ViewComponent<Props> {
  get componentName() {
    return ["report", "pages", "report", "ReportPageView"]
  }

  constructor(props: PageProps) {
    super(props)

    setupStyles(this.appConfig, props.reportVariant)

    this.state = {
      showHistoryData: false
    }
  }

  render() {
    super.render()

    const { language, onSetLanguage, reportVariant, isDownloadingPdf, onDownloadPdf } = this.props

    return (
      <>
        {reportVariant !== "pdf" && this.renderOverlayItems()}
        {reportVariant !== "pdf" && (
          <ReportTopBanner
            logo={`${this.appConfig.imagesRoot}/hintsa/logo_hintsa_white.svg`}
            language={language}
            onSetLanguage={onSetLanguage}
            reportVariant={reportVariant}
            isDownloadingPdf={isDownloadingPdf}
            onDownloadPdf={onDownloadPdf}
          />
        )}
        {this.renderReportBackground()}
        {this.renderReportArea()}
      </>
    )
  }

  private renderReportArea() {
    const { surveyResult, selectedReportIndex, isLoading, reportVariant, onSelectReport } = this.props

    if (isLoading) return null

    return (
      <div className={styles.reportRootContainer}>
        <Container maxWidth="lg" className={styles.baseContainer}>
          {reportVariant !== "pdf" && (
            <ReportSelector
              selectedReportIndex={selectedReportIndex}
              surveyResult={surveyResult}
              onSelectReport={onSelectReport}
            />
          )}
          <TransformElement selectedTabIndex={selectedReportIndex}>{this.renderAllReports()}</TransformElement>
        </Container>
      </div>
    )
  }

  private renderAllReports() {
    const {
      surveyResult,
      reportVariant,
      user,
      language,
      showCoachInfo,
      isDownloadingPdf,
      onDownloadPdf,
      onUpdateMyGoals
    } = this.props

    return surveyResult?.instances.map((answer, i, array) => {
      return (
        <WellbeingReport
          key={i} // indexes are static, this is the best id to use
          reportVariant={reportVariant}
          answer={answer}
          comparison={surveyResult.instances[i + 1]}
          isInitialReport={i === array.length - 1}
          survey={surveyResult.survey as Survey}
          user={user}
          language={language!}
          myGoals={surveyResult?.report_extra?.reportMyGoals}
          pdfName={this.pdfName}
          pdfUrl={this.pdfUrl}
          showCoachInfo={showCoachInfo}
          isDownloadingPdf={isDownloadingPdf}
          onDownloadPdf={onDownloadPdf}
          onUpdateMyGoals={onUpdateMyGoals}
        />
      )
    })
  }

  private renderReportBackground() {
    return (
      <>
        <div className={styles.reportBackground} />
        <div className={styles.reportBackgroundImage} />
        <div className={styles.reportBackgroundGradient} />
      </>
    )
  }

  private renderOverlayItems() {
    const { isLoading } = this.props

    return <FadedBlockDiv showBlockDiv={isLoading} />
  }
}

let styles

const setupStyles = (appConfig: AppConfig, reportVariant: ReportVariant) => {
  styles = stylesheet({
    reportRootContainer: {
      position: "absolute",
      top: "5rem",
      height: "100%",
      width: "100%",
      color: ReportPageView.theme.colors.fontLightColor.toString()
    },
    reportBackground: {
      position: "fixed",
      width: "100%",
      height: "100%",
      backgroundColor: ReportPageView.theme.colors.hintsa.charcoal.toString(),
      zIndex: -10
    },
    reportBackgroundImage: {
      position: "absolute",
      width: "100%",
      top: "-4.5rem",
      height: reportVariant === "mobile" ? "35rem" : "45rem",
      opacity: 0.5,
      backgroundImage: `url("${appConfig.imagesRoot}/wellbeing_report/report_bg.webp")`,
      backgroundSize: reportVariant === "mobile" ? "180vw " : "cover",
      backgroundRepeat: "no-repeat",
      zIndex: -5,
      backgroundPosition: reportVariant === "mobile" ? "-30vw 8rem" : undefined
    },
    reportBackgroundGradient: {
      position: "absolute",
      width: "100%",
      top: "10rem",
      height: reportVariant === "mobile" ? "21rem" : "30.5rem",
      zIndex: -1,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      background: `linear-gradient(to bottom, transparent, ${ReportPageView.theme.colors.hintsa.charcoal.toString()})`
    },
    baseContainer: {
      paddingLeft: reportVariant === "mobile" ? "0rem" : "2rem",
      paddingRight: reportVariant === "mobile" ? "0rem" : "2rem"
    }
  })
}
